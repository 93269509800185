<template>
    <main class="m-competition">
        <img src="http://cssn.lexiangwx.com/png/R358387779077574656.png" alt="财商少年全国大赛">
    </main>
</template>

<script>
    export default {
        name: "m-competition",
        metaInfo: {
            title: '财商少年 FQChildren',
            meta: [
                {
                    name: 'keyWords',
                    content: '全国少儿现金流游戏大赛，全国现金流大赛,现金流游戏，财商学院,财商少年'
                },
                {
                    name: 'description',
                    content: '中国财商少年每年举办全国现金流游戏大赛，许孩子一个确定而美好的未来。'
                }
            ]
        },
    }
</script>

<style lang="scss" scoped>
    .m-competition img {
        width: 100%;
    }
</style>
